<template>
  <v-row justify="center">
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      max-width="800px"
      v-if="resolutionScreen >= 500"
    >
      <v-card>
        <v-card-title
          >{{ $t("sharelinkupload.messageboxheader") }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35">mdi-link-variant-plus</v-icon>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height:550px">
          <v-layout row wrap class="pa-4">
            <v-flex xs12 lg12 v-if="dataAccountActive.type === 'Business'">
              <div
                v-if="
                  role_level === 'True' || filedata.file_permission_2 !== '01'
                "
              >
                <v-layout row wrap class="pa-4 pb-0 pt-0">
                  <!-- <v-flex xs4 lg4> -->
                  <v-switch
                    v-if="switch1 === true"
                    v-model="switch1"
                    :color="color.theme"
                    inset
                    :label="$t('sharelinkupload.statusYes')"
                    hide-details
                    @change="check_share()"
                  >
                  </v-switch>
                  <v-switch
                    v-if="switch1 === false"
                    v-model="switch1"
                    :color="color.theme"
                    inset
                    :label="$t('sharelinkupload.statusNo')"
                    hide-details
                    @change="check_share()"
                  ></v-switch>
                  <v-spacer></v-spacer>
                  <!-- type sharelink -->
                  <v-flex xs3 lg3> </v-flex>
                </v-layout>
                <br />
                <v-layout row wrap class="pa-4 pb-0 pt-0">
                  <v-flex xs12 sm12 lg10>
                    <v-text-field
                      id="textfield"
                      :color="color.theme"
                      v-model="link"
                      solo
                      name="input-7-4"
                      flat
                      label
                      outlined
                      rows="3"
                      dense
                      hide-details
                      :disabled="!disable_link"
                      class="mb-2 mr-2"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 lg1>
                    <v-btn
                      class="mt-0"
                      :disabled="!disable_link"
                      color="green"
                      :dark="disable_link"
                      @click="copytextarea()"
                    >
                      {{ $t("sharelinkupload.copy") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
                <br />
                <!-- qr code -->
                <v-layout
                  row
                  wrap
                  class="pa-4 pb-0 pt-0"
                  v-if="switch1 === true"
                >
                  <!-- <v-flex xs10 lg3> -->
                  <v-switch
                    v-if="switch2 === true"
                    v-model="switch2"
                    :color="color.theme"
                    inset
                    :label="$t('sharelinkupload.qrcodeOn')"
                    hide-details
                    @change="check_share()"
                  >
                  </v-switch>
                  <v-switch
                    v-if="switch2 === false"
                    v-model="switch2"
                    :color="color.theme"
                    inset
                    :label="$t('sharelinkupload.qrcodeOff')"
                    hide-details
                    @change="check_share()"
                  ></v-switch>
                  <!-- </v-flex> -->
                </v-layout>
                <v-layout
                  v-if="switch2 === true"
                  row
                  wrap
                  class="pa-4 pb-0 pt-0"
                >
                  <v-flex xs12 lg12 d-flex justify-center class="mt-2 mb-2">
                    <img
                      v-if="resolutionScreen >= 400"
                      id="img"
                      :src="'data:image/jpeg;base64,' + qr_code"
                      width="30%"
                    />
                    <img
                      v-else
                      id="img"
                      :src="'data:image/jpeg;base64,' + qr_code"
                      width="60%"
                    />
                  </v-flex>
                  <v-flex xs12 lg12 mx-3 d-flex justify-center>
                    <v-btn
                      class="mt-0"
                      :disabled="!disable_link"
                      color="green"
                      :dark="disable_link"
                      @click="saveqrcode()"
                    >
                      {{ $t("sharelinkupload.saveqrcode") }}
                    </v-btn>
                  </v-flex>
                </v-layout>
                <br />
                <v-layout v-if="switch1 === true" justify-end row wrap>
                  <v-flex lg6 sm6 xs11 class="text-left pl-3">
                    <p class="mb-0">
                      {{ $t("sharelinkupload.numberopen_1") }}:&nbsp;{{ countopen }} {{ $t("sharelinkupload.numberopen_2") }}
                    </p>
                  </v-flex>
                  <v-flex lg6 sm6 xs11 class="text-right pr-2">
                    <p class="mb-0" v-if="$t('default') === 'th'">
                      {{ $t("sharelinkupload.shareby") }}:&nbsp;{{
                        name_share_th === "System" ? $t("sharelinkupload.notshared") : name_share_th
                      }}
                    </p>
                    <p class="mb-0" v-else>
                      {{ $t("sharelinkupload.shareby") }}:&nbsp;{{
                        name_share_eng === "System" ? $t("sharelinkupload.notshared") : name_share_eng
                      }}
                    </p>
                  </v-flex>
                </v-layout>
                <br />
                <div v-if="switch1 === true">
                  <v-layout>
                    <h3 style="text-color:black;" class="mb-0">
                      {{ $t("sharelinkupload.setsharelinkfile") }}
                    </h3>
                  </v-layout>
                  <!-- กำหนดเวลาใหม่ -->
                  <v-layout row wrap>
                    <v-expansion-panels
                      flat
                      multiple
                      v-model="panel_time"
                      :readonly="!time_share_link_status"
                    >
                      <v-expansion-panel>
                        <v-expansion-panel-header class="pb-2">
                          <v-flex xs3 sm1 lg1>
                            <v-switch
                              @change="checkbutton_time(time_share_link_status)"
                              v-model="time_share_link_status"
                              :color="color.theme"
                              inset
                              hide-details
                              class="mt-1"
                            >
                            </v-switch>
                          </v-flex>
                          <v-flex xs9 sm11 lg11>
                            {{ $t("sharelinkupload.timeheader") }}
                          </v-flex>
                          <template v-slot:actions>
                            <v-icon color="error"></v-icon>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pb-0">
                          <v-layout row wrap>
                            <v-flex
                              xs2
                              lg1
                              sm1
                              class="pl-2 mt-3 mr-1"
                              v-if="pleasetimeinput === true"
                            >
                              <v-icon large color="red"
                                >mdi-alert-octagon</v-icon
                              >
                            </v-flex>
                            <v-flex class="mt-3 ml-2 mb-n4" lg5 sm3 xs9>
                              <v-dialog
                                persistent
                                v-model="modal2"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                width="290px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    clearable
                                    v-bind="attrs"
                                    v-on="on"
                                    v-model="picker"
                                    :label="$t('sharelinkupload.date_set')"
                                    readonly
                                    prepend-icon="mdi-calendar"
                                    outlined
                                    dense
                                  >
                                  </v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="picker"
                                  :min="datenow"
                                  @input="modal2 = false"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    dark
                                    color="error"
                                    @click="modal2 = false"
                                  >
                                    {{ $t("confirm.closecomfirm") }}
                                  </v-btn>
                                </v-date-picker>
                              </v-dialog>
                            </v-flex>
                            <v-flex
                              lg1
                              sm1
                              xs11
                              class="mt-3 pt-2 pl-4"
                              v-if="resolutionScreen >= 400"
                            >
                              <v-icon>access_time</v-icon>
                            </v-flex>
                            <v-flex class="mt-3 mb-n4" lg2 sm2 xs11>
                              <v-select
                                :label="$t('sharelinkupload.hours_set')"
                                outlined
                                dense
                                :items="hours"
                                v-model="hour"
                                class="ml-2"
                              >
                              </v-select>
                            </v-flex>
                            <v-flex class="mt-3 mb-n4" lg2 sm2 xs11>
                              <v-select
                                class="ml-2"
                                :label="$t('sharelinkupload.minute_set')"
                                outlined
                                dense
                                :items="minutes"
                                v-model="minute"
                              >
                              </v-select>
                            </v-flex>
                          </v-layout>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-layout>
                  <!-- กำหนดหมายเหตุ -->
                  <v-layout row wrap>
                    <v-flex lg12 class="px-4">
                      <v-card-title style="color: black; font-size: 16px">
                        {{ $t("sharelinkupload.addremark") }}
                      </v-card-title>
                    </v-flex>
                    <v-flex lg12 class="px-6">
                      <v-textarea
                        v-model="remarkshare"
                        outlined
                        name="input-7-4"
                        :label="$t('sharelinkupload.remark')"
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
              <div v-else-if="filedata.file_permission_2 === '01'">
                <v-switch
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  @change="updatestatusshare('Y')"
                  :label="$t('sharelinkupload.cannotshare')"
                  hide-details
                  readonly
                ></v-switch>
              </div>
            </v-flex>
            <!-- Citizen -->
            <v-flex xs12 lg12 v-else-if="dataAccountActive.type === 'Citizen'">
              <v-layout row wrap class="pa-4 pb-0 pt-0">
                <v-switch
                  v-if="switch1 === true"
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  :label="$t('sharelinkupload.statusYes')"
                  hide-details
                  @change="check_share()"
                >
                </v-switch>
                <v-switch
                  v-if="switch1 === false"
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  :label="$t('sharelinkupload.statusNo')"
                  hide-details
                  @change="check_share()"
                ></v-switch>
                <v-spacer></v-spacer>
                <v-flex xs3 lg3> </v-flex>
              </v-layout>
              <br />
              <v-layout row wrap class="pa-4 pb-0 pt-0">
                <v-flex xs12 sm12 lg10>
                  <v-text-field
                    id="textfield"
                    :color="color.theme"
                    v-model="link"
                    solo
                    name="input-7-4"
                    flat
                    label
                    outlined
                    rows="3"
                    dense
                    hide-details
                    :disabled="!disable_link"
                    class="mb-2 mr-2"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 lg1>
                  <v-btn
                    class="mt-0"
                    :disabled="!disable_link"
                    color="green"
                    :dark="disable_link"
                    @click="copytextarea()"
                  >
                    <!-- <v-icon>
                        file_copy
                      </v-icon> -->
                    {{ $t("sharelinkupload.copy") }}
                  </v-btn>
                </v-flex>
              </v-layout>
              <br />
              <!-- qr code -->
              <v-layout row wrap class="pa-4 pb-0 pt-0" v-if="switch1 === true">
                <!-- <v-flex xs10 lg10> -->
                <v-switch
                  v-if="switch2 === true"
                  v-model="switch2"
                  :color="color.theme"
                  inset
                  :label="$t('sharelinkupload.qrcodeOn')"
                  hide-details
                  @change="check_share()"
                >
                </v-switch>
                <v-switch
                  v-if="switch2 === false"
                  v-model="switch2"
                  :color="color.theme"
                  inset
                  :label="$t('sharelinkupload.qrcodeOff')"
                  hide-details
                  @change="check_share()"
                ></v-switch>
                <!-- </v-flex> -->
                <!-- type sharelink -->
                <!-- <v-flex xs3 lg3>
                    <v-select
                      v-model="typeshare"
                      :items="itemsshare"
                      label="การเข้าถึงทั่วไป"
                      dense
                      outlined
                      :disabled="!switch1"
                    ></v-select>
                  </v-flex> -->
              </v-layout>
              <v-layout v-if="switch2 === true" row wrap class="pa-4 pb-0 pt-0">
                <v-flex xs12 lg12 d-flex justify-center class="mt-2 mb-2">
                  <img
                    v-if="resolutionScreen >= 400"
                    id="img"
                    :src="'data:image/jpeg;base64,' + qr_code"
                    width="30%"
                  />
                  <img
                    v-else
                    id="img"
                    :src="'data:image/jpeg;base64,' + qr_code"
                    width="60%"
                  />
                </v-flex>
                <v-flex xs12 lg12 mx-3 d-flex justify-center>
                  <v-btn
                    class="mt-0"
                    :disabled="!disable_link"
                    color="green"
                    :dark="disable_link"
                    @click="saveqrcode()"
                  >
                    {{ $t("sharelinkupload.saveqrcode") }}
                  </v-btn>
                </v-flex>
              </v-layout>
              <br />
              <v-layout v-if="switch1 === true" justify-end row wrap>
                <v-flex lg6 sm6 xs11 class="text-left pl-3">
                  <p class="mb-0">
                    {{ $t("sharelinkupload.numberopen_1") }}:&nbsp;{{ countopen }} {{ $t("sharelinkupload.numberopen_2") }}
                  </p>
                </v-flex>
                <v-flex lg6 sm6 xs11 class="text-right pr-2">
                  <p class="mb-0" v-if="$t('default') === 'th'">
                    {{ $t("sharelinkupload.shareby") }}:&nbsp;{{
                      name_share_th === "System" ? $t("sharelinkupload.notshared") : name_share_th
                    }}
                  </p>
                  <p class="mb-0" v-else>
                    {{ $t("sharelinkupload.shareby") }}:&nbsp;{{
                      name_share_eng === "System" ? $t("sharelinkupload.notshared") : name_share_eng
                    }}
                  </p>
                </v-flex>
              </v-layout>
              <br />
              <div v-if="switch1 === true">
                <v-layout>
                  <h3 style="text-color:black;" class="mb-0">
                    {{ $t("sharelinkupload.setsharelinkfile") }}
                  </h3>
                </v-layout>
                <!-- กำหนดเวลาใหม่ -->
                <v-layout row wrap>
                  <v-expansion-panels
                    flat
                    multiple
                    v-model="panel_time"
                    :readonly="!time_share_link_status"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header class="pb-2">
                        <v-flex xs3 sm1 lg1>
                          <v-switch
                            @change="checkbutton_time(time_share_link_status)"
                            v-model="time_share_link_status"
                            :color="color.theme"
                            inset
                            hide-details
                            class="mt-1"
                          >
                          </v-switch>
                        </v-flex>
                        <v-flex xs9 sm11 lg11>
                          {{ $t("sharelinkupload.timeheader") }}
                        </v-flex>
                        <template v-slot:actions>
                          <v-icon color="error"></v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="pb-0">
                        <v-layout row wrap>
                          <v-flex
                            xs2
                            lg1
                            sm1
                            class="pl-2 mt-3 mr-1"
                            v-if="pleasetimeinput === true"
                          >
                            <v-icon large color="red">mdi-alert-octagon</v-icon>
                          </v-flex>
                          <v-flex class="mt-3 ml-2 mb-n4" lg5 sm3 xs9>
                            <v-dialog
                              persistent
                              v-model="modal2"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  clearable
                                  v-bind="attrs"
                                  v-on="on"
                                  v-model="picker"
                                  :label="$t('sharelinkupload.date_set')"
                                  readonly
                                  prepend-icon="mdi-calendar"
                                  outlined
                                  dense
                                >
                                </v-text-field>
                              </template>
                              <v-date-picker
                                v-model="picker"
                                :min="datenow"
                                @input="modal2 = false"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  dark
                                  color="error"
                                  @click="modal2 = false"
                                >
                                  {{ $t("confirm.closecomfirm") }}
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-flex>
                          <v-flex
                            lg1
                            sm1
                            xs11
                            class="mt-3 pt-2 pl-4"
                            v-if="resolutionScreen >= 400"
                          >
                            <v-icon>access_time</v-icon>
                          </v-flex>
                          <v-flex class="mt-3 mb-n4" lg2 sm2 xs11>
                            <v-select
                              :label="$t('sharelinkupload.hours_set')"
                              outlined
                              dense
                              :items="hours"
                              v-model="hour"
                              class="ml-2"
                            >
                            </v-select>
                          </v-flex>
                          <v-flex class="mt-3 mb-n4" lg2 sm2 xs11>
                            <v-select
                              class="ml-2"
                              :label="$t('sharelinkupload.minute_set')"
                              outlined
                              dense
                              :items="minutes"
                              v-model="minute"
                            >
                            </v-select>
                          </v-flex>
                        </v-layout>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-layout>
                <!-- กำหนดหมายเหตุ -->
                <v-layout row wrap>
                  <v-flex lg12 class="px-4">
                    <v-card-title style="color: black; font-size: 16px">
                      {{ $t("sharelinkupload.addremark") }}
                    </v-card-title>
                  </v-flex>
                  <v-flex lg12 class="px-6">
                    <v-textarea
                      v-model="remarkshare"
                      outlined
                      name="input-7-4"
                      :label="$t('sharelinkupload.remark')"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            outlined
            @click="
              $emit('close'),
                cleardata(),
                $emit('openDrag'),
                $emit('opendragselect')
            "
            >{{ $t("uploadfolder.buttonclose") }}</v-btn
          >
          <v-btn
            :loading="btnsetload"
            :color="color.theme"
            :dark="color.darkTheme"
            @click="updatesharelink()"
            >{{ $t("sharelinkupload.setting") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- หน้าจอมือถือ -->
    <v-dialog
      v-model="shower"
      scrollable
      persistent
      :max-width="maxWidthOnMobile"
      content-class="rounded-lg"
      v-else
    >
      <v-card>
        <v-card-text class="pa-5">
          <v-layout class="pt-2 pb-1">
            <v-flex d-flex justify-center class="ml-8">
              <span :style="headerPage">{{
                $t("sharelinkupload.messageboxheader")
              }}</span>
            </v-flex>
            <v-flex xs1 d-flex justify-end>
              <v-icon
                :color="$vuetify.theme.dark ? 'white' : color.theme"
                @click="
                  $emit('close'),
                    cleardata(),
                    $emit('openDrag'),
                    $emit('opendragselect')
                "
                >mdi-close</v-icon
              >
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-text style="height: 400px;">
          <v-layout row wrap class="pa-2">
            <v-flex xs12 lg12 v-if="dataAccountActive.type === 'Business'">
              <div
                v-if="
                  role_level === 'True' || filedata.file_permission_2 !== '01'
                "
              >
                <v-switch
                  v-if="switch1 === true"
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  :label="$t('sharelinkupload.statusYes')"
                  hide-details
                  @change="check_share()"
                  class="mb-4"
                >
                </v-switch>
                <v-switch
                  v-if="switch1 === false"
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  :label="$t('sharelinkupload.statusNo')"
                  hide-details
                  @change="check_share()"
                  class="mb-4"
                ></v-switch>
                <v-list-item class="mt-n2">
                  <v-text-field
                    id="textfield"
                    :color="color.theme"
                    v-model="link"
                    solo
                    name="input-7-4"
                    flat
                    label
                    outlined
                    rows="3"
                    dense
                    hide-details
                    :disabled="!disable_link"
                    class="mb-2 ml-n4"
                    style="width:20px;"
                  ></v-text-field>
                  <v-btn
                    class="mt-n2 mr-n4 ml-1"
                    :disabled="!disable_link"
                    color="green"
                    :dark="disable_link"
                    @click="copytextarea()"
                  >
                    <v-icon>mdi-link mdi-rotate-135</v-icon>
                    {{ $t("sharelinkupload.copy") }}
                  </v-btn>
                </v-list-item>
                <br />
                <v-layout row wrap v-if="switch1 === true">
                  <!-- qr code -->
                  <v-expansion-panels
                    v-model="switch2"
                    class="px-2 rounded-xl"
                    active-class="rounded-xl"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header class="my-n2 rounded-xl">
                        <span
                          ><v-icon small>mdi-qrcode-scan</v-icon
                          ><span :style="titleText" class="ml-2">{{
                            $t("sharelinkupload.showqrcode")
                          }}</span></span
                        >
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        class="py-2 rounded-xl"
                        elevation="0"
                      >
                        <v-flex
                          xs12
                          lg12
                          d-flex
                          justify-center
                          class="mt-2 mb-2"
                        >
                          <img
                            v-if="resolutionScreen >= 400"
                            id="img"
                            :src="'data:image/jpeg;base64,' + qr_code"
                            width="30%"
                          />
                          <img
                            v-else
                            id="img"
                            :src="'data:image/jpeg;base64,' + qr_code"
                            width="60%"
                          />
                        </v-flex>
                        <v-flex xs12 lg12 mx-3 d-flex justify-center>
                          <v-btn
                            class="mt-0"
                            :disabled="!disable_link"
                            color="green"
                            :dark="disable_link"
                            @click="saveqrcode()"
                            outlined
                          >
                            <v-icon small class="mr-1">mdi-qrcode-scan</v-icon
                            >{{ $t("sharelinkupload.saveqrcode") }}
                          </v-btn>
                        </v-flex>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <br />
                  <v-expansion-panels
                    class="mt-8 px-2 rounded-xl"
                    active-class="rounded-xl"
                    v-if="switch1 === true"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header class="my-n2 rounded-xl">
                        <span
                          ><v-icon small>mdi-cogs</v-icon
                          ><span class="ml-2" :style="titleText">{{
                            $t("sharelinkupload.setsharelinkfile")
                          }}</span></span
                        >
                      </v-expansion-panel-header>
                      <v-expansion-panel-content
                        class="py-2 mt-n2 rounded-xl"
                        elevation="0"
                      >
                        <v-layout v-if="switch1 === true" justify-end row wrap>
                          <v-flex lg6 sm6 xs11 class="text-left mt-4 mb-n2">
                            <p class="mb-0">
                              {{ $t("sharelinkupload.numberopen_1") }}:&nbsp;{{ countopen }} {{ $t("sharelinkupload.numberopen_2") }}
                            </p>
                          </v-flex>
                          <v-flex xs12 class="text-right mt-3 mb-n1">
                            <p class="mb-0" v-if="$t('default') === 'th'">
                              {{ $t("sharelinkupload.shareby") }}:&nbsp;{{
                                name_share_th === "System" ? $t("sharelinkupload.notshared") : name_share_th
                              }}
                            </p>
                            <p class="mb-0" v-else>
                              {{ $t("sharelinkupload.shareby") }}:&nbsp;{{
                                name_share_eng === "System" ? $t("sharelinkupload.notshared") : name_share_eng
                              }}
                            </p>
                          </v-flex>
                        </v-layout>
                        <div v-if="switch1 === true">
                          <!-- กำหนดเวลาใหม่ -->
                          <v-layout row wrap>
                            <v-expansion-panels
                              flat
                              multiple
                              v-model="panel_time"
                              :readonly="!time_share_link_status"
                            >
                              <v-expansion-panel>
                                <v-expansion-panel-header class="pb-2">
                                  <v-flex xs3 class="px-n4">
                                    <v-switch
                                      @change="
                                        checkbutton_time(time_share_link_status)
                                      "
                                      v-model="time_share_link_status"
                                      :color="color.theme"
                                      inset
                                      hide-details
                                      class="mt-1"
                                    >
                                    </v-switch>
                                  </v-flex>
                                  <v-flex xs11>
                                    {{ $t("sharelinkupload.timeheader") }}
                                  </v-flex>
                                  <template v-slot:actions>
                                    <v-icon color="error"></v-icon>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content class="pb-0">
                                  <v-layout row wrap>
                                    <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                      <v-dialog
                                        persistent
                                        v-model="modal2"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        width="290px"
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }"
                                        >
                                          <v-text-field
                                            clearable
                                            v-bind="attrs"
                                            v-on="on"
                                            v-model="picker"
                                            :label="
                                              $t('sharelinkupload.date_set')
                                            "
                                            readonly
                                            prepend-icon="mdi-calendar"
                                            outlined
                                            dense
                                          >
                                          </v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="picker"
                                          :min="datenow"
                                          @input="modal2 = false"
                                        >
                                          <v-spacer></v-spacer>
                                          <v-btn
                                            dark
                                            color="error"
                                            @click="modal2 = false"
                                          >
                                            {{ $t("confirm.closecomfirm") }}
                                          </v-btn>
                                        </v-date-picker>
                                      </v-dialog>
                                    </v-flex>
                                    <v-flex
                                      xs2
                                      class="mt-3 pl-1"
                                      v-if="pleasetimeinput === true"
                                    >
                                      <v-icon large color="red"
                                        >mdi-alert-octagon</v-icon
                                      >
                                    </v-flex>
                                    <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                      <v-select
                                        :label="$t('sharelinkupload.hours_set')"
                                        outlined
                                        dense
                                        :items="hours"
                                        v-model="hour"
                                        prepend-icon="access_time"
                                      >
                                      </v-select>
                                    </v-flex>
                                    <v-flex
                                      xs2
                                      class="mt-3 pl-1"
                                      v-if="pleasetimeinput === true"
                                    >
                                      <v-icon large color="red"
                                        >mdi-alert-octagon</v-icon
                                      >
                                    </v-flex>
                                    <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                      <v-select
                                        prepend-icon="access_time"
                                        :label="$t('sharelinkupload.minute_set')"
                                        outlined
                                        dense
                                        :items="minutes"
                                        v-model="minute"
                                      >
                                      </v-select>
                                    </v-flex>
                                    <v-flex
                                      xs2
                                      class="mt-3 pl-1"
                                      v-if="pleasetimeinput === true"
                                    >
                                      <v-icon large color="red"
                                        >mdi-alert-octagon</v-icon
                                      >
                                    </v-flex>
                                  </v-layout>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-layout>
                          <!-- กำหนดหมายเหตุ -->
                          <v-layout row wrap>
                            <v-flex lg12 class="px-4">
                              <v-card-title
                                style="color: black; font-size: 16px"
                              >
                                {{ $t("sharelinkupload.addremark") }}
                              </v-card-title>
                            </v-flex>
                            <v-flex lg12 class="px-6">
                              <v-textarea
                                v-model="remarkshare"
                                outlined
                                name="input-7-4"
                                :label="$t('sharelinkupload.remark')"
                              ></v-textarea>
                            </v-flex>
                          </v-layout>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-layout>
              </div>
              <div v-else-if="filedata.file_permission_2 === '01'">
                <v-switch
                  v-model="switch1"
                  :color="color.theme"
                  inset
                  @change="updatestatusshare('Y')"
                  :label="$t('sharelinkupload.cannotshare')"
                  hide-details
                  readonly
                ></v-switch>
              </div>
            </v-flex>
            <!-- Citizen -->
            <v-flex xs12 lg12 v-else-if="dataAccountActive.type === 'Citizen'">
              <v-switch
                v-if="switch1 === true"
                v-model="switch1"
                :color="color.theme"
                inset
                :label="$t('sharelinkupload.statusYes')"
                hide-details
                @change="check_share()"
                class="mb-4"
              >
              </v-switch>
              <v-switch
                v-if="switch1 === false"
                v-model="switch1"
                :color="color.theme"
                inset
                :label="$t('sharelinkupload.statusNo')"
                hide-details
                @change="check_share()"
                class="mb-4"
              ></v-switch>
              <v-list-item class="mt-n2">
                <v-text-field
                  :color="color.theme"
                  v-model="link"
                  solo
                  name="input-7-4"
                  flat
                  label
                  outlined
                  rows="3"
                  dense
                  hide-details
                  :disabled="!disable_link"
                  class="mb-2 ml-n4"
                  style="width:20px;"
                  id="textfield"
                ></v-text-field>
                <v-btn
                  class="mt-n2 mr-n4 ml-1"
                  :disabled="!disable_link"
                  color="green"
                  :dark="disable_link"
                  @click="copytextarea()"
                >
                  <v-icon>mdi-link mdi-rotate-135</v-icon>
                  {{ $t("sharelinkupload.copy") }}
                </v-btn>
              </v-list-item>
              <br />
              <v-layout row wrap v-if="switch1 === true">
                <!-- qr code -->
                <v-expansion-panels
                  v-model="switch2"
                  class="px-2 rounded-xl"
                  active-class="rounded-xl"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header class="my-n2 rounded-xl">
                      <span
                        ><v-icon small>mdi-qrcode-scan</v-icon
                        ><span :style="titleText" class="ml-2">{{
                          $t("sharelinkupload.showqrcode")
                        }}</span></span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="py-2 rounded-xl"
                      elevation="0"
                    >
                      <v-flex xs12 lg12 d-flex justify-center class="mt-2 mb-2">
                        <img
                          v-if="resolutionScreen >= 400"
                          id="img"
                          :src="'data:image/jpeg;base64,' + qr_code"
                          width="30%"
                        />
                        <img
                          v-else
                          id="img"
                          :src="'data:image/jpeg;base64,' + qr_code"
                          width="60%"
                        />
                      </v-flex>
                      <v-flex xs12 lg12 mx-3 d-flex justify-center class="mb-4">
                        <v-btn
                          class="mt-0"
                          :disabled="!disable_link"
                          :color="color.theme"
                          :dark="disable_link"
                          @click="saveqrcode()"
                          outlined
                        >
                          <v-icon small class="mr-1">mdi-qrcode-scan</v-icon
                          >{{ $t("sharelinkupload.saveqrcode") }}
                        </v-btn>
                      </v-flex>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br />
                <v-expansion-panels
                  class="mt-8 px-2 rounded-xl"
                  active-class="rounded-xl"
                  v-if="switch1 === true"
                >
                  <v-expansion-panel>
                    <v-expansion-panel-header class="my-n2 rounded-xl">
                      <span
                        ><v-icon small>mdi-cogs</v-icon
                        ><span class="ml-2" :style="titleText">{{
                          $t("sharelinkupload.setsharelinkfile")
                        }}</span></span
                      >
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                      class="py-2 rounded-xl"
                      elevation="0"
                    >
                      <v-layout
                        v-if="switch1 === true"
                        justify-end
                        row
                        wrap
                        class="mt-n6"
                      >
                        <v-flex lg6 sm6 xs11 class="text-left mt-4 mb-n2">
                          <p class="mb-0">
                            {{ $t("sharelinkupload.numberopen_1") }}:&nbsp;{{ countopen }} {{ $t("sharelinkupload.numberopen_2") }}
                          </p>
                        </v-flex>
                        <v-flex xs12 class="text-right mt-3 mb-n1">
                          <p class="mb-0" v-if="$t('default') === 'th'">
                            {{ $t("sharelinkupload.shareby") }}:&nbsp;{{
                              name_share_th === "System" ? $t("sharelinkupload.notshared") : name_share_th
                            }}
                          </p>
                          <p class="mb-0" v-else>
                            {{ $t("sharelinkupload.shareby") }}:&nbsp;{{
                              name_share_eng === "System" ? $t("sharelinkupload.notshared") : name_share_eng
                            }}
                          </p>
                        </v-flex>
                      </v-layout>
                      <div v-if="switch1 === true">
                        <!-- กำหนดเวลาใหม่ -->
                        <v-layout row wrap>
                          <v-expansion-panels
                            flat
                            multiple
                            v-model="panel_time"
                            :readonly="!time_share_link_status"
                          >
                            <v-expansion-panel>
                              <v-expansion-panel-header class="pb-2">
                                <v-flex xs3 class="px-n4">
                                  <v-switch
                                    @change="
                                      checkbutton_time(time_share_link_status)
                                    "
                                    v-model="time_share_link_status"
                                    :color="color.theme"
                                    inset
                                    hide-details
                                    class="mt-1"
                                  >
                                  </v-switch>
                                </v-flex>
                                <v-flex xs11 :style="titleText">
                                  {{ $t("sharelinkupload.timeheader") }}
                                </v-flex>
                                <template v-slot:actions>
                                  <v-icon color="error"></v-icon>
                                </template>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content class="pb-0">
                                <v-layout row wrap>
                                  <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                    <v-dialog
                                      persistent
                                      v-model="modal2"
                                      :close-on-content-click="false"
                                      transition="scale-transition"
                                      offset-y
                                      width="290px"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-text-field
                                          clearable
                                          v-bind="attrs"
                                          v-on="on"
                                          v-model="picker"
                                          :label="$t('sharelinkupload.date_set')"
                                          readonly
                                          prepend-icon="mdi-calendar"
                                          outlined
                                          dense
                                        >
                                        </v-text-field>
                                      </template>
                                      <v-date-picker
                                        v-model="picker"
                                        :min="datenow"
                                        @input="modal2 = false"
                                      >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          dark
                                          color="error"
                                          @click="modal2 = false"
                                        >
                                          {{ $t("confirm.closecomfirm") }}
                                        </v-btn>
                                      </v-date-picker>
                                    </v-dialog>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    class="mt-3 pl-1"
                                    v-if="pleasetimeinput === true"
                                  >
                                    <v-icon large color="red"
                                      >mdi-alert-octagon</v-icon
                                    >
                                  </v-flex>
                                  <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                    <v-select
                                      prepend-icon="access_time"
                                      :label="$t('sharelinkupload.hours_set')"
                                      outlined
                                      dense
                                      :items="hours"
                                      v-model="hour"
                                    >
                                    </v-select>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    class="mt-3 pl-1"
                                    v-if="pleasetimeinput === true"
                                  >
                                    <v-icon large color="red"
                                      >mdi-alert-octagon</v-icon
                                    >
                                  </v-flex>
                                  <v-flex class="mt-3 ml-5 mb-n4" xs9>
                                    <v-select
                                      prepend-icon="access_time"
                                      :label="$t('sharelinkupload.minute_set')"
                                      outlined
                                      dense
                                      :items="minutes"
                                      v-model="minute"
                                    >
                                    </v-select>
                                  </v-flex>
                                  <v-flex
                                    xs2
                                    class="mt-3 pl-1"
                                    v-if="pleasetimeinput === true"
                                  >
                                    <v-icon large color="red"
                                      >mdi-alert-octagon</v-icon
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-layout>
                        <!-- กำหนดหมายเหตุ -->
                        <v-layout row wrap>
                          <v-flex lg12 class="px-4">
                            <v-card-title
                              style="color: black; font-size: 16px"
                            >
                              {{ $t("sharelinkupload.addremark") }}
                            </v-card-title>
                          </v-flex>
                          <v-flex lg12 class="px-6">
                            <v-textarea
                              v-model="remarkshare"
                              outlined
                              name="input-7-4"
                              :label="$t('sharelinkupload.remark')"
                            ></v-textarea>
                          </v-flex>
                        </v-layout>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card-text>
        <div class="text-center my-4">
          <v-btn
            :style="btnAction"
            class="mr-6"
            height="40px"
            width="40%"
            color="red"
            outlined
            @click="
              $emit('close'),
                cleardata(),
                $emit('openDrag'),
                $emit('opendragselect')
            "
            >{{ $t("uploadfolder.buttonclose") }}</v-btn
          >
          <v-btn
            class="elevation-0"
            :style="btnAction"
            height="40px"
            width="40%"
            :color="color.theme"
            :dark="color.darkTheme"
            @click="updatesharelink()"
            >{{ $t("sharelinkupload.setting") }}</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { requiredIf } from "vuelidate/lib/validators";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import format from "date-fns/format";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  mixins: [validationMixin],
  validations: {
    // foldername: { required },
    inputs: {
      $each: {
        picker: {
          requiredIf: requiredIf(function(input) {
            return (
              input.picker === null &&
              (input.hour !== "" || input.minute !== "")
            );
          }),
        },
        hour: {
          requiredIf: requiredIf(function(input) {
            return (
              input.hour === "" &&
              (input.picker !== null || input.minute !== "")
            );
          }),
        },
        minute: {
          requiredIF: requiredIf(function(input) {
            return (
              input.minute === "" &&
              (input.picker !== null || input.hour !== "")
            );
          }),
        },
      },
    },
  },
  name: "onebox_dialogSharefile",
  props: ["show", "parentfolder", "filedata"],
  data: function() {
    return {
      listemailalert: [],
      inputs: [
        {
          email: "",
          permission: { name: "view", key: "01", text: "sharefile.view" },
          modal: "",
          hour: "",
          minute: "",
          picker: null,
        },
      ],
      typeshare: "ทุกคนที่มีลิงก์",
      itemsshare: ["ทุกคนที่มีลิงก์", "จำกัด"],
      status_error_conut: 0,
      panel_permission: [1],
      panel_number: [1],
      panel_time: [1],
      panel_password: [1],
      panel_watermark: [0],
      ownerpassword: false,
      showpassword: false,
      password_link: "",
      password_share_link_status: false,
      datetimecheck: "",
      dateymd: "",
      datehour: "",
      dateminute: "",
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      minutes: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
      ],
      minute: "",
      hour: "",
      picker: "",
      datenow: "",
      modal2: false,
      modal: false,
      time_share_link_status: false,
      permission_value: "",
      values_permis: [
        { name: "view", key: "01", text: "sharefile.view" },
        { name: "download", key: "02", text: "sharefile.download" },
        // { name: "upload", key: "03", text: "sharefile.upload" },
        // { name: "edit", key: "04", text: "sharefile.edit" },
        // { name: "delete", key: "05", text: "sharefile.delete" },
      ],
      values_file: [
        { name: "view", key: "01", text: "sharefile.view" },
        { name: "download", key: "02", text: "sharefile.download" },
        // { name: "upload", key: "03", text: "sharefile.upload" },
        // { name: "edit", key: "04", text: "sharefile.edit" },
        // { name: "delete", key: "05", text: "sharefile.delete" },
      ],
      values_file_viewonly: [
        { name: "view", key: "01", text: "sharefile.view" },
        // { name: "download", key: "02", text: "sharefile.download" },
      ],
      values_folder: [
        { name: "view", key: "01", text: "sharefile.view" },
        { name: "download", key: "02", text: "sharefile.download" },
        // { name: "upload", key: "03", text: "sharefile.upload" },
        // { name: "edit", key: "04", text: "sharefile.edit" },
        // { name: "delete", key: "05", text: "sharefile.delete" },
      ],
      values_folder_viewonly: [
        { name: "view", key: "01", text: "sharefile.view" },
        // { name: "download", key: "02", text: "sharefile.download" },
      ],
      btnsetload: false,
      pleaselimitopeninput: false,
      pleasetimeinput: false,
      pleasepasswordinput: false,
      showReload: false,
      link: "",
      loading: true,
      switch1: false,
      switch2: false,
      foldername: "",
      createprogress: false,
      disable_link: false,
      status_share: "",
      limit_status: false,
      permission_status: false,
      access_limit_count: 0,
      access_limit: 0,
      name_share_th: "",
      name_share_eng: "",
      qr_code: "",
      status_watermark: false,
      remarkshare: "",
      countopen: 0,
    };
  },
  computed: {
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "color",
      "role_level",
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    // style อักษร header ของ mobile
    headerPage() {
      return (
        "color:" +
        this.color.theme +
        ";" +
        "font-size: 16px; font-weight: 600; font-weight: bold; letter-spacing: 0px;"
      );
    },
    // style อักษรใน dialog ของ mobile
    titleText() {
      return "color:black; font-size: 14px; line-height: 24px;";
    },
    // style ปุ่ม ของ mobile
    btnAction() {
      return "font-size: 16px; font-weight: lighter;";
    },
    // ความกว้างของ dialog ของ ipad และ mobile
    maxWidthOnMobile() {
      if (this.resolutionScreen >= 400) {
        return 600;
      } else {
        return 346;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    foldernameErrors() {
      const errors = [];
      if (!this.$v.foldername.$dirty) return errors;
      !this.$v.foldername.required && errors.push("กรุณากรอกชื่อ โฟลเดอร์");
      return errors;
    },
  },
  watch: {
    show: function(val) {
      if (val === true) {
        this.datenow = format(new Date(), "yyyy-MM-dd");
        this.sharefolder();
        this.$emit("closeDrag");
      } else {
        this.$emit("openDrag");
      }
    },
  },
  methods: {
    checkbutton_number(_swicth) {
      console.log(_swicth);

      this.panel_number = [1];
      console.log(this.panel_number);
    },
    checkbutton_permission(_swicth) {
      console.log(_swicth);

      this.panel_permission = [1];
      console.log(this.panel_number);
    },
    checkbutton_time(_swicth) {
      console.log(_swicth);
      this.panel_time = [1];
      // console.log(this.panel_number);
    },
    checkbutton_password(_swicth) {
      console.log(_swicth);

      this.panel_password = [1];
      //  console.log(this.panel_number);
    },
    check_share() {
      if (this.switch1 === false) {
        this.limit_status = false;
        this.password_share_link_status = false;
        this.time_share_link_status = false;
        this.permission_status = false;
        this.panel_permission = [1];
        this.panel_number = [1];
        this.panel_time = [1];
        this.panel_password = [1];
      }
    },
    copytextarea() {
      let input = document.getElementById("textfield");
      input.select();
      document.execCommand("copy");
      Toast.fire({
        icon: "success",
        title: this.$t("sharelinkupload.copylinksucces"),
      });
    },
    async saveqrcode() {
      if (this.resolutionScreen > 500) {
        try {
          const handle = await window.showSaveFilePicker({
            suggestedName: "QR-Code Share link file",
            types: [
              {
                description: "Images",
                accept: {
                  "image/jpeg": ".jpeg",
                },
              },
            ],
            excludeAcceptAllOption: true,
          });
          const writable = await handle.createWritable();
          let input = document.getElementById("img").src;
          let response = await fetch(`${input}`);
          let imgblob = await response.blob();
          let blob = new Blob([imgblob], { type: imgblob.type });
          await writable.write(blob);
          await writable.close();
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = handle.name;
          link.click();

          Toast.fire({
            icon: "success",
            title: this.$t("sharelinkupload.saveqrsucces"),
          });
          return handle;
        } catch (err) {
          console.error(err.name, err.message);
        }
      } else {
        let input = document.getElementById("img").src;
        let response = await fetch(`${input}`);
        let imgblob = await response.blob();
        let blob = new Blob([imgblob], { type: imgblob.type });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "QR-Code Share link file";
        link.click();
      }
    },
    updatestatusshare(status) {
      this.loading = true;
      this.sharefolder();
      this.status_share = status;
    },
    checkdatetime() {
      console.log(this.picker, this.hour, this.minute);
      if (this.picker === "" || this.picker == null) {
        this.dateymd = "00000000";
      } else {
        let dateyear =
          this.picker.split("")[0] +
          this.picker.split("")[1] +
          this.picker.split("")[2] +
          this.picker.split("")[3];

        let datemount = this.picker.split("")[5] + this.picker.split("")[6];
        let dateday = this.picker.split("")[8] + this.picker.split("")[9];
        this.dateymd = dateyear + datemount + dateday;
      }
      if (this.hour !== "") {
        this.datehour = this.hour.split("")[0] + this.hour.split("")[1];
      } else {
        this.datehour = "00";
      }
      if (this.minute !== "") {
        this.dateminute = this.minute.split("")[0] + this.minute.split("")[1];
      } else {
        this.dateminute = "00";
      }
      let datesecond = "00";

      this.datetimecheck =
        this.dateymd + this.datehour + this.dateminute + datesecond;
      console.log("datetimecheck", this.datetimecheck);
    },
    async updatesharelink() {
      this.checkdatetime();
      this.btnsetload = true;
      let switch1 = this.switch1;
      let time_share_link_status = this.time_share_link_status;

      if (switch1 === false) {
        time_share_link_status = false;
      }
      let payload = {
        data_id: this.filedata["file_id"],
        account_id: this.dataAccountId,
        status: switch1 === true ? (switch1 = "Y") : (switch1 = "N"),
        time_share_link: this.datetimecheck || null,
        time_share_link_status:
          time_share_link_status === true
            ? (time_share_link_status = "Y")
            : (time_share_link_status = "N"),
        remark: this.remarkshare,
      };
      console.log("payload", payload);
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/share_data_link_upload",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      try {
        if (response.data.status === "OK") {
          console.log(this.switch1);
          this.disable_link = this.switch1;
          if (this.switch1 === false) {
            this.limit_status = this.switch1;
          }
          this.pleasetimeinput = false;
          this.btnsetload = false;
          this.name_share_th = response.data.result["share_by_th"];
          this.name_share_eng = response.data.result["share_by_eng"];
          Toast.fire({
            icon: "success",
            title: this.$t("sharelinkupload.settingsharelinkfilesucces"),
          });
        } else if (response.data.errorCode === "ER403") {
          if (
            this.time_share_link_status === true &&
            this.dateymd == "00000000"
          ) {
            this.status_error_conut += 1;
            this.pleasetimeinput = true;
          } else {
            this.pleasetimeinput = false;
          }

          if (this.status_error_conut > 1) {
            Toast.fire({
              icon: "error",
              title: this.$t("sharelinkupload.sharelinkfilemanyerror"),
            });
          } else {
            if (
              this.time_share_link_status === true &&
              this.dateymd == "00000000"
            ) {
              Toast.fire({
                icon: "error",
                title: this.$t("sharelinkupload.choosedate"),
              });
            }
          }
          this.loading = false;
          this.btnsetload = false;
        } else {
          this.loading = false;
          this.btnsetload = false;
          Toast.fire({
            icon: "error",
            title: this.$t("sharelinkupload.sharelinkfileerror"),
            
          });
        }
      } catch (ex) {
        this.btnsetload = false;
        this.loading = false;
        console.log(ex);
        Toast.fire({
          icon: "error",
          title: this.$t("sharelinkupload.sharelinkfileerror"),
        });
      }
    },
    fn_time_shareperpeople(time, hour, minute) {
      console.log(time);
      console.log(hour);
      console.log(minute);
      let timeshare_link_perpeople = "";
      if (time !== undefined && time !== null && time !== "") {
        let timeshare_ = time.split("-");
        timeshare_link_perpeople =
          timeshare_[0] + timeshare_[1] + timeshare_[2] + hour + minute;
        console.log("timeshare_", timeshare_);
      }
      console.log("timeshare_link_perpeople", timeshare_link_perpeople);
      return timeshare_link_perpeople;
    },
    async sharefolder() {
      this.password_link = "";
      let payload = {
        data_id: this.filedata["file_id"],
        data_type: "folder",
        account_id: this.dataAccountId,
      };
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        process.env.VUE_APP_SERVICE_SHARE_FOLDER +
          "/api/share_link_upload/generate_share_link",
        payload,
        {
          headers: { Authorization: auth.code },
          withCredentials: false,
        }
      );

      try {
        if (response.data.status === "OK") {
          this.loading = false;
          console.log(response.data);
          let status_share = response.data.result["status_share"];
          let status_share2 = response.data.result["status_share"];
          this.switch1 =
            status_share === "Y" || status_share === "P"
              ? (status_share = true)
              : (status_share = false);
          this.disable_link =
            status_share2 === "Y" || status_share2 === "P"
              ? (status_share2 = true)
              : (status_share2 = false);
          this.link =
            process.env.VUE_APP_UI +
            "/sharelinkuploads?id=" +
            response.data.result["link"];
          this.name_share_th = response.data.result["name_shared_th"];
          this.name_share_eng = response.data.result["name_shared_eng"];
          let time_share_link = response.data.result["time_share_link_status"];
          this.time_share_link_status =
            time_share_link === "Y"
              ? (this.time_share_link_status = true)
              : (this.time_share_link_status = false);
          if (time_share_link === "Y") {
            this.panel_time = [0];
          }
          if (response.data.result["time_share_link"] !== null) {
            let date_year =
              response.data.result["time_share_link"].split("")[0] +
              response.data.result["time_share_link"].split("")[1] +
              response.data.result["time_share_link"].split("")[2] +
              response.data.result["time_share_link"].split("")[3];
            let date_mount =
              response.data.result["time_share_link"].split("")[4] +
              response.data.result["time_share_link"].split("")[5];
            let date_day =
              response.data.result["time_share_link"].split("")[6] +
              response.data.result["time_share_link"].split("")[7];
            this.picker = date_year + "-" + date_mount + "-" + date_day;

            this.hour =
              response.data.result["time_share_link"].split("")[8] +
                response.data.result["time_share_link"].split("")[9] || "";
            this.minute =
              response.data.result["time_share_link"].split("")[10] +
                response.data.result["time_share_link"].split("")[11] || "";
          } else {
            this.picker = "";
            this.hour = "";
            this.minute = "";
          }
          this.qr_code = response.data.result["qr_code"];
          this.countopen = response.data.result["count_opened"];
          this.remarkshare = response.data.result["remark"];
        } else {
          this.loading = false;
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage,
          });
        }
      } catch (ex) {
        this.loading = false;
        console.log(ex);
        Toast.fire({
          icon: "error",
          title: this.$t("sharelinkupload.sharelinkfileerror"),
        });
      }
    },
    async resetshare() {
      let payload;
      if (this.filedata["file_type"] === "folder") {
        payload = {
          data_id: this.filedata["file_id"],
          data_type: "folder",
        };
      } else {
        payload = {
          data_id: this.filedata["file_id"],
          data_type: "file",
        };
      }
      let auth = await gbfGenerate.generateToken();
      var response = await this.axios.post(
        // process.env.VUE_APP_SERVICE_SHARE_FOLDER + "/api/share_folder_link", payload, {
        process.env.VUE_APP_SERVICE_SHARE_FOLDER +
          "/api/share_link/reset_access_limit_count",
        payload,
        {
          headers: { Authorization: auth.code },
        }
      );
      try {
        if (response.data.status === "OK") {
          this.access_limit_count = response.data.access_limit_count;
        } else {
          Toast.fire({
            icon: "error",
            title: this.$t("sharelinkupload.sharelinkfileerror"),
          });
        }
      } catch {}
    },
    checkPermission(permission) {
      let permission_value = {};
      if (permission === "01") {
        permission_value = {
          name: "view",
          key: "01",
          text: "sharefile.view",
        };
      } else if (permission === "02") {
        permission_value = {
          name: "download",
          key: "02",
          text: "sharefile.download",
        };
      } else if (permission === "03") {
        permission_value = {
          name: "upload",
          key: "03",
          text: "sharefile.upload",
        };
      } else if (permission === "04") {
        permission_value = {
          name: "edit",
          key: "04",
          text: "sharefile.edit",
        };
      } else if (permission === "05") {
        permission_value = {
          name: "delete",
          key: "05",
          text: "sharefile.delete",
        };
      }
      return permission_value;
    },
    cleardata() {
      this.$v.$reset();
      this.foldername = "";
      this.createprogress = false;
      this.switch1 = false;
      this.switch2 = false;
      this.access_limit = 0;
      this.limit_status === false;
      this.disable_link = false;
      this.pleasepasswordinput = false;
      this.pleaselimitopeninput = false;
      this.pleasetimeinput = false;
      this.permission_status = "";
      this.permission_value = "";
      this.time_share_link_status = false;
      this.password_share_link_status = false;
      this.password_link = "";
      this.panel_permission = [1];
      this.panel_number = [1];
      this.panel_time = [1];
      this.panel_password = [1];
      this.inputs = [];
      this.btnsetload = false;
      this.remarkshare = "";
    },
  },
};
</script>
<style>
.v-expansion-panel-content__wrap {
  padding-top: 0;
  padding-right: 24px;
  padding-bottom: 0px;
  padding-left: 24px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: 100%;
}
</style>
